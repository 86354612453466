<template>
  <div class="container-fluid">
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <div class="table-header">Список продуктов ({{ productCount }})</div>
    </div>

    <div class="card">
      <div class="card-header">Фильтры</div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-2">
            <div class="form-group">
              <label class="form-label fs-14">Поиск по названию</label>
              <input @keyup.enter="getProductList" type="text" class="form-control fs-14" placeholder="Поиск..."
                     v-model="filter.search.title"/>
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group">
              <label class="form-label fs-14">Наличие товара</label>
              <select class="form-select fs-14" v-model="filter.isAvailable">
                <option value="">Выберите</option>
                <option value="0">Не в наличии</option>
                <option value="1">В наличии</option>
              </select>
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group">
              <label class="form-label fs-14">Наличие категории</label>
              <select class="form-select fs-14" v-model="filter.isCategory">
                <option value="">Выберите</option>
                <option value="0">Без категории</option>
                <option value="1">С категорией</option>
              </select>
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group">
              <label class="form-label fs-14">Наличие фото</label>
              <select class="form-select fs-14" v-model="filter.isImage">
                <option value="">Выберите</option>
                <option value="0">Без фото</option>
                <option value="1">С фото</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group">
              <input type="checkbox" class="btn-check" id="btncheck1" autocomplete="off"
                     v-model="filter.isPriceLessYandex" true-value="1" false-value="0">
              <label class="btn btn-outline-primary" for="btncheck1">Цена в яндексе меньше, чем на сайте</label>
            </div>
          </div>
        </div>

        <div class="btn-toolbar mt-3">
          <div class="btn-group me-2">
            <button type="button" class="btn btn-primary" @click="showTreeCategoryModal">Выбрать категорию</button>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="d-flex align-items-center">
          <div class="btn-group ms-auto">
            <button type="button" class="btn btn-warning" @click="clearFilters">Сбросить фильтр</button>
            <button type="button" class="btn btn-success" @click="getProductList">Найти</button>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-end mt-4 mb-2">
      <a class="btn btn-info text-white" @click="exportActionProduct">Выгрузить товары со скидкой</a>
      <p class="form-label text-end">Показано: {{ this.filter.limit }} из {{ productCount }}</p>
    </div>

    <table class="table table-striped table-hover table-bordered fs-12">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Фото</th>
        <th scope="col">Название</th>
        <th scope="col">Категории</th>
        <th scope="col">Цена</th>
        <th scope="col">Остаток</th>
        <th scope="col">Видимость</th>
        <th scope="col">Порядок</th>
      </tr>
      </thead>
      <tbody v-if="productList.length > 0">
      <tr v-for="(item, index) in productList" :key="item.id">
        <th scope="row">{{ index + 1 }}</th>
        <td>
          <img class="product-image" :src="appImageUrl + item.eco_medium_image"/>
        </td>
        <td>
          <a :href="'/view-product/' + item.id" target="_blank">
            {{ item.title }} ECO ID - {{ item.eco_id }}
          </a>
        </td>
        <td>
          <div class="categories">
            <div class="item"
                 v-for="category in getNonEmptyCategories(item.categories)"
                 :key="category">
              {{ category }}
            </div>
          </div>
        </td>
        <td>
          <div v-for="region in item.productsAvailables" :key="region.id">
            {{ arrayCity[region.region] }} - {{ region.price }} / Yandex - {{ region.yandex_price }}
          </div>
        </td>
        <td>
          <div class="category-item" v-for="region in item.productsAvailables" :key="region.id">
            {{ arrayCity[region.region] }} - {{ region.available }}
          </div>
        </td>
        <td>
          <div class="form-check d-flex justify-content-center">
            <input class="form-check-input" type="checkbox" @change="changeVisible(item.id)"
                   :checked="parseInt(item.active)">
          </div>
        </td>
        <td class="text-center">
          <input class="very-small" type="number" @change="editSort(item.id, $event.target.value)" placeholder="Порядок"
                 :value="item.sort">
        </td>
      </tr>
      </tbody>
      <tbody v-else>Ничего не найдено</tbody>
    </table>
    <div class="row">
      <div class="col text-center">
        <a class="btn btn-primary" @click="getMore" v-if="productList.length < productCount">Показать ещё</a>
      </div>
    </div>
    <tree-category v-show="this.$store.state.modal.treeCategory"
                   @close="closeTreeCategoryModal"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TreeCategory from "../../components/modal/products/tree-category";

export default {
  name: 'list-products',
  components: {TreeCategory},
  data: function () {
    return {
      appImageUrl: process.env.VUE_APP_IMAGE_URL,
      filter: {
        limit: 15,
        isCategory: '',
        isAvailable: '',
        isImage: '',
        isPriceLessYandex: '',
        search: {
          title: '',
          barcode: '',
          priceFrom: 1,
          priceTo: 100000,
          weightFrom: 1,
          weightTo: 100000,
        },
      },
      arrayCity: {
        77: 'Москва',
        78: 'СПБ'
      }
    }
  },
  computed: {
    ...mapGetters(['productList', 'productCount', 'productCategories']),
    getNonEmptyCategories() {
      return (categories) => {
        return categories.filter(category => category.trim !== '');
      };
    }
  },
  async mounted() {
    this.getProductList();
  },
  methods: {
    exportActionProduct: function () {
      this.$store.dispatch('exportActionProduct').then((res) => {
        if (res.data.result == 'ok') {
          let link = document.createElement('a');
          link.href = res.data.data.link;
          link.setAttribute('DOWNLOAD', res.data.data.filename);
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.$toast.success(res.data.message);
        } else {
          this.$toast.success(res.data.message);
        }
      });
    },
    getMore: function () {
      this.filter.limit += 15;
      this.getProductList();
    },
    getProductList: function () {
      this.$store.dispatch('getProductList', this.filter);
    },
    changeVisible: function (id) {
      this.$store.dispatch('changeActiveProduct', id);
    },
    editSort: function (id, value) {
      this.$store.dispatch('editSortProduct', {'id': id, 'sort': value});
    },
    clearFilters: function () {
      this.filter.isImage = ''
      this.filter.isCategory = ''
      this.filter.isAvailable = ''
      this.filter.search.title = '';
      this.filter.search.barcode = '';
      this.filter.search.priceFrom = 1;
      this.filter.search.priceTo = 100000;
      this.filter.search.weightFrom = 1;
      this.filter.search.weightTo = 100000;
      this.$root.$emit('updateTreeCategories');
      this.$store.commit('updateFilterCategories', {mCategory: [], sCategory: []});
      this.getProductList();
    },
    showTreeCategoryModal() {
      this.$store.state.modal.treeCategory = true;
    },
    closeTreeCategoryModal() {
      this.$store.state.modal.treeCategory = false;
    },
  },
}
</script>

<style lang="scss" scoped>
.product-image {
  max-width: 50px;
}

.categories {
  display: flex;
  justify-content: flex-start;
  flex-flow: wrap;
  max-width: 150px;

  .item {
    background: #d3dae2;
    font-weight: bold;
    font-size: 12px;
    padding: 5px 5px;
    margin: 5px 5px 0 0;
  }
}
</style>
